$(document).ready(function () {
    $(".carousel-home").owlCarousel({
        loop: true,
        margin: 0,
        nav: true,
        autoplay: true,
        autoplayTimeout: 7000,
        autoplayHoverPause: true,
        items: 1,
    });
    $('#header .header-mobile .botao-menu').click(function () {
        $(this).toggleClass('-aberto')
        $('#header .header-mobile .menu').toggleClass('-aberto');
    });
    $('[data-toggle="tooltip"]').tooltip();

    Polos.buscaEstadoCidade();

    Curso.selectPolos();
    Curso.filtroPresencialEAD();
    Noticias.buscaCategoria();
    Hotsite.Dropdown();

    Site.Cookies();
});


Curso = {};
Curso.selectPolos = function () {
    $selectPolo = $('#curso .inscreva .select-polo');
    $btnInscreva = $('#curso .inscreva .btn-inscreva').parents('a');
    $selectPolo.change(function () {
        if ($(this).val()) {
            $btnInscreva.attr('href', $(this).val());
            $btnInscreva.attr('onclick', "goog_report_conversion ('" + $(this).val() + "')");
            $('#curso .inscreva .btn-inscreva').tooltip('disable');
        } else {
            $btnInscreva.removeAttr('href');
            $('#curso .inscreva .btn-inscreva').tooltip('enable');
        }
    });
}
Curso.filtroPresencialEAD = function () {
    const $inputsType = $('input[name="type"]');


    const courseTypes = {
        'graduacao-presencial': {text: 'Graduação', big: 'Presencial', class: '.graduação.presencial'},
        'graduacao-semipresencial': {text: 'Graduação', big: 'Semipresencial', class: '.graduação.semipresencial'},
        'graduacao-ead': {text: 'Graduação', big: 'EAD', class: '.graduação.ead'},
        'pos-graduacao-presencial': {text: 'Pós-Graduação', big: 'Presencial', class: '.pós-graduação.presencial'},
        'pos-graduacao-semipresencial': {
            text: 'Pós-Graduação',
            big: 'Semipresencial',
            class: '.pós-graduação.semipresencial'
        },
        'pos-graduacao-ead': {text: 'Pós-Graduação', big: 'EAD', class: '.pós-graduação.ead'},
        'mestrado-presencial': {text: '', big: 'Mestrado', class: '.mestrado.presencial'},
        'mestrado-semipresencial': {text: '', big: 'Mestrado', class: '.mestrado.semipresencial'},
        'mestrado-ead': {text: '', big: 'Mestrado', class: '.mestrado.ead'},
        'extensao-presencial': {text: 'Extensão', big: 'Presencial', class: '.extensão.presencial'},
        'extensao-semipresencial': {text: 'Extensão', big: 'Semipresencial', class: '.extensão.semipresencial'},
        'extensao-ead': {text: 'Extensão', big: 'EAD', class: '.extensão.ead'},
    };

    $inputsType.change(function () {
        $('.cursos .curso').fadeOut(400);
        $inputsType.removeClass('checked');

        const courseType = courseTypes[this.value];
        if (courseType) {
            $('#cursos .banner .titulo .text').html(courseType.text);
            $('#cursos .banner .titulo .big').html(courseType.big);
            $(`.cursos .curso${courseType.class}`).fadeIn(400);
            $(this).addClass('checked');
        }
    });

    // Get anchor from URL
    let anchor = window.top.location.hash.substring(1);

    if (anchor) {
        $inputsType.each(function () {
            if (this.value === anchor) {
                console.log(this.value)
                $(this).attr('checked', 'checked');
            }
        });
    } else {
        anchor = 'graduacao-presencial';
    }

    $('input:radio[value="' + anchor + '"]').trigger("change");
}

Polos = {};
Polos.buscaEstadoCidade = function () {
    $('#filtro_estado').change(function () {
        $("#filtro_cidade option.cidades").hide();
        $("#filtro_cidade option[data-estado=" + $(this).val() + "]").show();
    });
    $('#filtro_cidade').change(function () {
        window.location.replace("?cidade=" + $(this).val());
    });
}

Noticias = {};
Noticias.buscaCategoria = function () {
    $('#filtro-categoria').change(function () {
        window.location.replace("?categoria=" + $(this).val());
    });
}

let Hotsite = {};
Hotsite.Dropdown = () => {
    $('#lab-select').on('change', function () {
        if ($(this).val() !== '') {
            window.location.href = $(this).val();
        }
    });
}

Site = {};
Site.Cookies = function () {
    lgpd = readCookie('lgpd');
    if (!lgpd) {
        $('#bannerCookies').css('display', 'flex');
    }
    $('#bannerCookies .btn-negar').click(function () {
        $('#bannerCookies').css('display', 'none');
    })
    $('#bannerCookies .btn-aceitar').click(function () {
        $('#bannerCookies').css('display', 'none');
        createCookie('lgpd', 'accepted', 365);
    })
}

function createCookie(name, value, days) {
    var expires;

    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    } else {
        expires = "";
    }
    document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
}

function readCookie(name) {
    var nameEQ = encodeURIComponent(name) + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ')
            c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0)
            return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name, "", -1);
}